@import 'node_modules/angular-notifier/styles';

body {
  height: 100%;
}

#invalid-feedback {
  color: var(--bs-danger);
}

.fill-white {
  fill: #fff;
}

.finicity-error-dialog-container .mat-dialog-container {
  padding: 0;
  min-width: 400px;
}
